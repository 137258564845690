import API from './api';
import { Organization } from '@/models';
import Application from '../models/application';
import {CancelTokenSource} from 'axios';

class MerchantAPI extends API {
    public login(credential: object): Promise<any> {
        return this.apiCall(this.client.post('auth/login', credential));
    }

    public balance(): Promise<any> {
        return this.apiCall(this.client.get('user/balance'));
    }

    public register(credential: object): Promise<any> {
        return this.apiCall(this.client.post('auth/register', credential));
    }

    public organizations(search: object): Promise<any> {
        return this.apiCall(this.client.get('organizations', {
            params: search,
        }));
    }

    public organizationCreate(organization: Organization): Promise<any> {
        return this.apiCall(this.client.post<Organization>('organizations', {
            title: organization.title,
            address: organization.address,
            bin: organization.bin,
            iik: organization.iik,
            bik: organization.bik,
        }));
    }

    public organizationUpdate(organization: Organization): Promise<any> {
        return this.apiCall(this.client.patch('organizations/' + organization.id, organization));
    }

    public organizationFind(): Promise<any> {
        return this.apiCall(this.client.get(`organizations`));
    }

    public organizationDestroy(id: string): Promise<any> {
        return this.apiCall(this.client.delete(`organizations/${id}`));
    }

    public requestToProduction(appKey: string, organization: Organization) {
        return this.apiCall(this.client.post(`production-requests`, {
            key: appKey,
            organization: organization.id,
        }));
    }

    public passwordForget(email: string) {
        return this.apiCall(this.client.post(`password/email`, { email }));
    }

    public passwordReset(token: string, email: string, password: string, passwordConfirmation: string) {
        return this.apiCall(this.client.post(`password/reset`, {
            token,
            email,
            password,
            password_confirmation: passwordConfirmation,
        }));
    }

    public emailResend(email: string) {
        return this.apiCall(this.client.post(`email/resend`, { email }));
    }

    // Payments
    public payments(cancel: CancelTokenSource, search: object): Promise<any> {
        return this.apiCall(this.client.get('payments', {
            params: search,
            cancelToken: cancel.token
        }));
    }

    public paymentFind(hash: string): Promise<any> {
        return this.apiCall(this.client.get(`payments/${hash}`));
    }

    public paymentsExport(file: string, search: object) {
        return this.apiCall(this.client.get(`payments/${file}/export`, {
            params: search,
            responseType: 'blob'
        }));
    }

    // Payouts
    public payouts(cancel: CancelTokenSource, search: object): Promise<any> {
        return this.apiCall(this.client.get('payouts', {
            params: search,
            cancelToken: cancel.token
        }));
    }

    public payoutFind(hash: string): Promise<any> {
        return this.apiCall(this.client.get(`payouts/${hash}`));
    }

    public payoutExport(file: string, search: object) {
        return this.apiCall(this.client.get(`payouts/${file}/export`, {
            params: search,
            responseType: 'blob'
        }));
    }

    public applications(search: object): Promise<any> {
        return this.apiCall(this.client.get('applications', {
            params: search,
        }));
    }

    public applicationCreate(app: Application): Promise<any> {
        return this.apiCall(this.client.post<Application>('applications', app));
    }

    public applicationUpdate(app: Application): Promise<any> {
        return this.apiCall(this.client.patch<Application>('applications/' + app.key, app));
    }

    public applicationFind(appKey: string): Promise<any> {
        return this.apiCall(this.client.get<Application>(`applications/${appKey}`));
    }

    public applicationDestroy(appKey: string): Promise<any> {
        return this.apiCall(this.client.delete(`applications/${appKey}`));
    }

    public dashboard(search: object): Promise<any> {
        return this.apiCall(this.client.get('dashboard', {
            params: search,
        }));
    }

    public organizationUploadFile(id: number, file: File): Promise<any> {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        };

        const formData = new FormData();
        formData.append('documents', file);

        return this.apiCall(
          this.client.post(`organizations/${id}/files`, formData, config)
        );
    }

    public organizationDeleteFile(id: number, path: string) {
        return this.apiCall(
          this.client.delete(`organizations/${id}/files/${path}`)
        );
    }
}

// @ts-ignore
export default new MerchantAPI(Platform.VUE_APP_API_MERCHANT_BASE_URL);
