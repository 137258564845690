import verified from '@/router/middleware/Verified';

export default [
    {
        path: '/', name: 'Login', meta: {middleware: ['guest']},
        component: () => import('@/views/login/Login.vue'),
    },
    {
        path: '/register', name: 'Registration', meta: {middleware: ['guest']},
        component: () => import('@/views/login/Registration.vue'),
    },
    {
        path: '/payments', name: 'Payments', meta: {middleware: ['auth', 'verified']},
        component: () => import('@/views/Payments.vue'),
    },
    {
        path: '/payout', name: 'Payout', meta: {middleware: ['auth', 'verified']},
        component: () => import('@/views/Payout.vue'),
    },
    {
        path: '/payments/:hash', name: 'PaymentDetails', meta: {middleware: ['auth', 'verified']},
        component: () => import('@/views/PaymentDetails.vue'),
    },
    {
        path: '/applications', name: 'Applications', meta: {middleware: ['auth', 'verified']},
        component: () => import('@/views/Applications.vue'),
    },
    {
        path: '/applications/create', name: 'ApplicationCreate', meta: {middleware: ['auth', 'verified']},
        component: () => import('@/views/forms/ApplicationForm.vue'),
    },
    {
        path: '/applications/:appKey/edit', name: 'ApplicationEdit', meta: {middleware: ['auth', 'verified']},
        component: () => import('@/views/forms/ApplicationForm.vue'), props: true,
    },
    {
        path: '/applications/:appKey/bench', name: 'ApplicationBench', meta: {middleware: ['auth', 'verified']},
        component: () => import('@/views/forms/ApplicationBenchForm.vue'),
    },
    {
        path: '/applications/:appKey/benchout', name: 'ApplicationOutBench', meta: {middleware: ['auth', 'verified']},
        component: () => import('@/views/forms/ApplicationOutBenchForm.vue'),
    },
    {
        path: '/organization', name: 'Organization', meta: {middleware: ['auth', 'verified']},
        component: () => import('@/views/forms/OrganizationForm.vue'),
    },
    {
        path: '/dashboard', name: 'Dashboard', meta: {middleware: ['auth']},
        component: () => import('@/views/Dashboard.vue'),
    },
    {
        path: '/password/reset', name: 'ForgetPassword', meta: {middleware: ['guest']},
        component: () => import('@/views/login/ForgetPassword.vue'),
    },
    {
        path: '/password/reset/:token', name: 'ResetPassword', meta: {middleware: ['guest']},
        component: () => import('@/views/login/ResetPassword.vue'),
    },
    {
        path: '/email/verification/fail', name: 'EmailVerificationFail', meta: {},
        component: () => import('@/views/EmailVerificationFail.vue'),
    },
    {
        path: '/:pathMatch(.*)*', name: 'NotFound',
        component: () => import('@/views/errors/NotFound.vue'),
    }
];
