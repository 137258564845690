







































import Vue from 'vue';
import Component from 'vue-class-component';
import LangSelector from '@/components/LangSelector.vue';
import User from '../models/user';
import { MerchantAPI } from '@/api';
import BalanceResponse from '@/api/response/balance';


@Component({
  components: {
    LangSelector
  },
})
export default class Navigator extends Vue {

  public balance: number = 0.00;

  public mounted() {
    setTimeout(this.getBalance, 500);
    setInterval(this.getBalance, 10000);
  }

  private user(): User | null {
    return this.$store.getters['auth/user'];
  }

  public userPayout(): boolean {
    const user = this.user();

    return user !== null && user.payout;
  }

  public getBalance() {

    if (this.userPayout()) {
      MerchantAPI
        .balance()
        .then((rr: BalanceResponse) => {
          this.balance = rr.balance;
        })
    }
  }

  public logout() {
    this.$store.dispatch('auth/logout')
      .then(() => window.location.href = '/')
      .catch((err: any) => alert(err));
  }
}
